<template>
  <div :id="formName">
    <b-sidebar :id="`${formName}-sidebar`" :visible="edition" @change="editionChange" @hidden="reset()">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ title }} {{ i18nEditionModeTitle }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer #default="{ invalid, validate, pristine }" :ref="`${formName}-sidebar-observer`">
          <b-form :ref="`${formName}-sidebar-form`" class="p-2" @submit.prevent="validate().then(submit)" @reset.prevent="reset">
            <slot name="fields" :item="formEntity.edited"></slot>
            <!-- submit, reset and delete -->
            <app-form-actions
              :form-name="formName"
              :reset-disabled="pristine || disabled"
              :show-reset="showReset"
              :show-delete="showDelete"
              :show-submit-and-next="showSubmitAndNext"
              :show-submit="showSubmit"
              :delete-btn-text="deleteBtnText"
              :delete-btn-icon="deleteBtnIcon"
              :submit-disabled="invalid || (noPristine ? null : pristine) || disabled"
              :submit-btn-text="submitBtnText"
              :submit-btn-variant="submitBtnVariant"
              :submit-btn-icon="submitBtnIcon"
              class="mt-2"
              @cancel="hide"
              @reset="$emit('reset')"
              @delete="$emit('delete')"
            />
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import AppFormActions from '@/components/AppFormActions.vue'

export default {
  name: 'AppSidebarForm',

  components: {
    AppFormActions,
  },

  props: {
    // The form name
    formName: {
      type: String,
      default: '',
    },
    // Show or not the sidebar
    edition: {
      type: Boolean,
      default: false,
    },
    // The edition mode
    // 'show', 'edit', 'add' or 'duplicate'
    editionModeTitle: {
      type: String,
      default: 'edit',
    },
    // Disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    // The title of sidebar
    title: {
      type: String,
      default: '',
    },
    // The entity to edit with the form
    entity: {
      type: Object,
      default: () => ({}),
    },
    deleteBtnText: {
      type: String,
      default: '',
    },
    deleteBtnIcon: {
      type: String,
      default: '',
    },
    submitBtnText: {
      type: String,
      default: '',
    },
    submitBtnVariant: {
      type: String,
      default: '',
    },
    submitBtnIcon: {
      type: String,
      default: '',
    },
    noPristine: {
      type: Boolean,
      default: false,
    },
    noCloseOnSubmit: {
      type: Boolean,
      default: false,
    },
    manualClose: {
      type: Boolean,
      default: false,
    },
    externalRequest: {
      type: Function,
      default: null,
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: undefined,
    },
    showSubmitAndNext: {
      type: Boolean,
      default: undefined,
    },
  },

  data() {
    return {
      formEntity: {
        old: {},
        edited: {},
      },
    }
  },

  computed: {
    i18nEditionModeTitle() {
      if (this.editionModeTitle === 'show') return this.$t('action.show')
      if (this.editionModeTitle === 'add') return this.$t('action.addition')
      if (this.editionModeTitle === 'edit') return this.$t('action.edition')
      if (this.editionModeTitle === 'duplicate') return this.$t('action.duplication')
      if (this.editionModeTitle === 'delete') return this.$t('action.deletion')
      return this.$t('action.edit')
    },
  },

  watch: {
    manualClose: {
      handler(value) {
        if (value) {
          this.reset()
          this.$emit('update:edition', false)
        }
      },
    },
  },

  methods: {
    reset() {
      this.formEntity.edited = this._cloneDeep(this.formEntity.old)
      this.$refs[`${this.formName}-sidebar-observer`].reset()
    },

    save() {
      this.formEntity.edited = this._cloneDeep(this.entity)
      this.formEntity.old = this._cloneDeep(this.formEntity.edited)
    },

    submit() {
      if (this.externalRequest && !this.noCloseOnSubmit) {
        return this.externalRequest(this.formEntity.edited).then(() => {
          this.$emit('update:entity', this.formEntity.edited)
          this.reset()
          this.$emit('update:edition', false)
        })
      }

      this.$emit('update:entity', this.formEntity.edited)
      if (!this.noCloseOnSubmit) {
        this.reset()
        this.$emit('update:edition', false)
      }
      return null
    },

    editionChange(isEditing) {
      this.$emit('update:edition', isEditing)
      if (isEditing) {
        this.save()
      }
    },
  },
}
</script>
