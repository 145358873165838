<template>
  <div id="timeline-step-transfer-helicopter-organizations-list">
    <!-- TABLE -->
    <app-data-table
      ref="timeline-step-transfer-helicopter-organizations-list"
      :bottom-table="{ totalCount: organizationsMeta.totalItems }"
      :fields="organizationsFields"
      :hover="false"
      :items="organizationsMeta.organizations"
      borderless
      primary-key="id"
      table-name="timeline-step-transfer-helicopter-organizations-list"
      thead-class="d-none"
      @pagination="$emit('refetch-data', { page: $event })"
    >
      <!-- CELL -->
      <!-- organization -->
      <template #cell(organization)="row">
        <b-card>
          <b-row>
            <b-col class="align-items-center justify-content-start p-0" cols="4">
              <b-img :src="row.item.logoFileUrl ? row.item.logoFileUrl : require('@/assets/images/logo/logo.png')" class="product-img" fluid height="auto" width="300px" />
            </b-col>

            <b-col class="align-items-center justify-content-start p-0" cols="8">
              <b-link :to="{
                name: 'provider-details-helicopter',
                params: {
                  provider_id: row.item.id,
                  trip_id: $route.params.trip_id,
                  step_id: $route.params.step_id,
                  event_id: $route.params.event_id,
                }
              }">
                {{ row.item.name }}
              </b-link>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="row.item.description"></p>
            </b-col>

            <b-col>
              <div class="d-flex justify-content-center mb-1">
                <b-button
                  v-b-toggle="'collapse_helicopter-' + type + '-' + row.item.id"
                  v-ripple.400="'rgba(220, 193, 129, 0.15)'"
                  variant="light"
                  class="mx-1"
                  size="sm"
                  @click="toggleDetails(row)"
                >
                  {{ selectedOrganizationId === row.item.id ? $t('service.action.hide_services') : $t('service.action.show_services') }}
                  <font-awesome-icon :icon="selectedOrganizationId === row.item.id ? 'chevron-down' : 'chevron-up'" class="ml-50" size="sm" />
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-collapse :id="'collapse_helicopter-' + type + '-' + row.item.id">
            <b-overlay :show="serviceLoading">
              <b-card v-for="service in services" :key="service.id" :img-alt="`service-logo-${service.id}`" class="border">
                <b-col class="d-flex justify-content-start p-0">
                  <div class="col-4 p-0">
                    <b-img
                      :src="
                        service.mainVariant.files.length ? service.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')
                      "
                      class="product-img"
                      fluid
                      height="auto"
                      width="300px"
                    />
                  </div>
                  <div class="m-0 pr-0 col-8 d-flex flex-wrap align-items-start">
                    <div class="col-12">
                      <div class="d-flex flex-column align-items-end">
                        <span v-if="service.departureName && service.arrivalName">{{ service.departureName }} > {{ service.arrivalName }}</span>
                        <b-link v-if="(service.departureDepositZones && service.departureDepositZones.length) || (service.arrivalDepositZones && service.arrivalDepositZones.length)" @click="selectedService = service">Consult deposit zones</b-link>
                      </div>
                      <div>
                        <h3>
                          {{ service.name | trans }}
                        </h3>
                        <b-col class="col=12 pr-0">
                          <div>
                            <h5 class="font-weight-bold">{{ $t('service.technical_informations.title') }}</h5>
                            <ul v-if="service.mainVariant && service.mainVariant.variantAttributes.length">
                              <li v-for="attribute in service.mainVariant.variantAttributes" :key="attribute.id">
                                {{ attribute.attribute.name | trans }} :
                                <span v-if="attribute.inputValues">{{ attribute.inputValues | trans }}</span>
                                <span v-if="attribute.selectValue">{{ attribute.selectValue.name | trans }}</span>
                                <ul v-if="attribute.choiceValues">
                                  <li v-for="choice in attribute.choiceValues" :key="choice.id" class="my-1">
                                    <b-link :href="choice.iconFileUrl" target="_blank">
                                      <img v-if="choice.iconFileUrl" height="40px" :src="choice.iconFileUrl" :alt="choice.name">
                                    </b-link>
                                    {{ choice.name | trans }}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </b-col>
                      </div>
                    </div>
                    <div class="col-12 align-self-end">
                      <b-col class="d-flex align-items-center justify-content-end p-0">
                        <h4 class="item-price m-0">
                          <span v-if="!service.minPrice" class="h5">
                            {{ $t('trip.shop.price_on_quote') }}
                          </span>
                          <span v-else> {{ service.minPrice.displayPriceIncludingTaxes | priceFormat }} {{ $t('trip.shop.cart.incl_taxes') }}</span>
                        </h4>
                        <b-button
                          class="btn-cart ml-1"
                          variant="primary"
                          :to="{
                            name: 'shop-service-helicopter-details',
                            params: {
                              trip_id: $route.params.trip_id,
                              step_id: $route.params.step_id,
                              event_id: $route.params.event_id,
                              required_organization_id: row.item.id,
                              destination_id: service.minPrice ? service.minPrice.id : service.mainVariant.id,
                              type: service.minPrice ? 'regular' : 'on-quote'
                            } }"
                        >
                          <span>{{ $t('trip.shop.view_details') }}</span>
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </b-col>
              </b-card>
            </b-overlay>
          </b-collapse>
        </b-card>
      </template>
    </app-data-table>

    <app-sidebar-form
      id="depositZones"
      :edition="!!selectedService"
      :entity="selectedService"
      :show-reset="false"
      :show-submit="false"
      :title="$t('service.helicopter.bases.address_deposit_zone')"
      edition-mode-title="show"
      form-name="deposit-zones"
      @update:edition="!$event && (selectedService = null)"
    >
      <template #fields="{ item }">
        {{ item.departureName }}
        <ul>
          <li v-for="departureDepositZone in item.departureDepositZones" :key="departureDepositZone.id">{{ getFullAddress(departureDepositZone) }}</li>
        </ul>
        {{ item.arrivalName }}
        <ul>
          <li v-for="arrivalDepositZone in item.arrivalDepositZones" :key="arrivalDepositZone.id">{{ getFullAddress(arrivalDepositZone) }}</li>
        </ul>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'

import AppDataTable from '@/components/AppDataTable.vue'
import {
  fetchTripStepEventOnQuoteHelicopterRequest,
  fetchTripStepEventRegularFlightHelicopterRequest,
} from '@/request/globalApi/requests/tripStepEventRequests'
import AppSidebarForm from '@/components/AppSidebarForm.vue'

export default defineComponent({
  name: 'TimelineStepTransferHelicopterOrganizationsList',

  components: {
    AppDataTable,
    AppSidebarForm,
  },

  props: {
    type: {
      type: String,
      default: null,
      required: true,
    },
    organizationsMeta: {
      type: Object,
      default: () => ({
        firstPage: 1,
        lastPage: 1,
        nextPage: null,
        previousPage: null,
        totalItems: 0,
        organizations: [],
      }),
    },
  },

  setup(props, ctx) {
    const services = ref([])
    const serviceLoading = ref(false)
    const showDepositZones = ref(false)
    const selectedService = ref(null)
    const selectedOrganizationId = ref(null)

    const organizationsFields = computed(() => [{ key: 'organization' }])
    const servicesFields = computed(() => [{ key: 'service' }])

    const getFullAddress = item => {
      const addressName = item.addressName ? `${item.addressName},` : ''
      return `${addressName} ${item.address}, ${item.postalCode} ${item.city}`
    }

    const resolveProductImgScrc = product => {
      if (product?.mainVariant?.files?.length) return product?.mainVariant?.files[0]?.fileUrls[300]
      if (product?.mainCategory?.files?.length) return product?.mainCategory?.iconFileUrl
      return require('@/assets/images/logo/logo.png')
    }

    const toggleDetails = row => {
      if (selectedOrganizationId.value !== row.item.id) {
        const eventId = ctx.root.$route.params.event_id
        selectedOrganizationId.value = row.item.id
        const action = props.type === 'regular-line'
          ? fetchTripStepEventRegularFlightHelicopterRequest
          : fetchTripStepEventOnQuoteHelicopterRequest

        serviceLoading.value = true
        action(eventId, selectedOrganizationId.value).then(({ data }) => {
          services.value = data.services
        }).finally(() => {
          serviceLoading.value = false
        })
      } else {
        selectedOrganizationId.value = null
      }
    }

    const getFileUrl = product => {
      if (product?.mainVariant?.files?.length) return product?.mainVariant?.files[0]?.fileUrls[300]
      if (product?.mainCategory?.files?.length) return product?.mainCategory?.iconFileUrl
      return require('@/assets/images/logo/logo.png')
    }

    return {
      organizationsFields,
      servicesFields,
      services,
      serviceLoading,
      showDepositZones,
      selectedService,
      getFullAddress,
      resolveProductImgScrc,
      toggleDetails,
      getFileUrl,
      selectedOrganizationId,
    }
  },
})
</script>

<style lang="scss" scoped>
.table-card {
  min-height: 400px;
}
</style>
