<template>
  <div>
    <h1 class="m-2">{{ $t('timeline.helicopter.regular_flight') }}</h1>

    <b-overlay :show="regularFlightOrganizationsLoading" class="my-3" spinner-medium>
      <TimelineStepTransferHelicopterOrganizationsList
        :organizations-meta="regularFlightOrganizations"
        type="regular-line"
        @refetch-data="fetchTripStepEventOrganizationsRegularFlight($event)"
      />
    </b-overlay>

    <h1 class="m-2">{{ $t('timeline.helicopter.on_quote_flight') }}</h1>
    <b-overlay :show="onQuoteFlightOrganizationsLoading" class="my-3" spinner-medium>
      <TimelineStepTransferHelicopterOrganizationsList
        :organizations-meta="onQuoteFlightOrganizations"
        type="on-quote-line"
        @refetch-data="fetchTripStepEventOrganizationsOnQuote($event)"
      />
    </b-overlay>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

import TimelineStepTransferHelicopterOrganizationsList
from '@/views/timeline/step/TimelineStepTransferHelicopterOrganizationsList.vue'
import {
  fetchTripStepEventOrganizationsOnQuoteRequest,
  fetchTripStepEventOrganizationsRegularFlightRequest,
} from '@/request/globalApi/requests/tripStepEventRequests'

export default defineComponent({
  name: 'TimelineStepTransferHelicopterOrganizations',

  components: {
    TimelineStepTransferHelicopterOrganizationsList,
  },

  setup(props, ctx) {
    const regularFlightOrganizations = ref({})
    const onQuoteFlightOrganizations = ref({})
    const regularFlightOrganizationsLoading = ref(false)
    const onQuoteFlightOrganizationsLoading = ref(false)

    const fetchTripStepEventOrganizationsOnQuote = (queryParams = {}) => {
      onQuoteFlightOrganizationsLoading.value = true
      fetchTripStepEventOrganizationsOnQuoteRequest(ctx.root.$route.params.event_id, queryParams).then(({ data }) => {
        onQuoteFlightOrganizations.value = data
      }).finally(() => {
        onQuoteFlightOrganizationsLoading.value = false
      })
    }

    const fetchTripStepEventOrganizationsRegularFlight = (queryParams = {}) => {
      regularFlightOrganizationsLoading.value = true
      fetchTripStepEventOrganizationsRegularFlightRequest(ctx.root.$route.params.event_id, queryParams).then(({ data }) => {
        regularFlightOrganizations.value = data
      }).finally(() => {
        regularFlightOrganizationsLoading.value = false
      })
    }

    fetchTripStepEventOrganizationsOnQuote()
    fetchTripStepEventOrganizationsRegularFlight()

    return {
      regularFlightOrganizations,
      onQuoteFlightOrganizations,
      regularFlightOrganizationsLoading,
      onQuoteFlightOrganizationsLoading,
      fetchTripStepEventOrganizationsOnQuote,
      fetchTripStepEventOrganizationsRegularFlight,
      // helicopter,
    }
  },
})
</script>
