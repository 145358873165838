<template>
  <div :id="`${formName}-form-actions`">
    <b-row>
      <b-col v-if="showSubmitAndNext" v-bind="initCols">
        <b-button
          :id="`${formName}-form-action-btn-submit-and-next`"
          :disabled="submitDisabled"
          type="submit"
          :block="$store.getters[`app/${breakpoint}AndDown`]"
          :size="size"
          class="w-100 mb-2"
          @click.prevent="$emit('save-and-next')"
        >
          {{ $t('action.save_and_next') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="showReset" v-bind="initCols">
        <b-button
          :id="`${formName}-form-action-btn-reset`"
          :disabled="resetDisabled"
          type="reset"
          variant="outline-secondary"
          :block="$store.getters[`app/${breakpoint}AndDown`]"
          :size="size"
          class="w-100"
          @click="$emit('reset')"
        >
          {{ $t('action.reset') }}
        </b-button>
      </b-col>
      <b-col v-if="showCancel" v-bind="initCols">
        <b-button
          :id="`${formName}-form-action-btn-cancel`"
          :disabled="cancelDisabled"
          type="button"
          variant="outline-secondary"
          :block="$store.getters[`app/${breakpoint}AndDown`]"
          :size="size"
          class="w-100"
          @click.prevent="$emit('cancel')"
        >
          {{ $t('action.cancel') }}
        </b-button>
      </b-col>
      <b-col v-if="showDelete" v-bind="initCols">
        <b-button
          :id="`${formName}-form-action-btn-delete`"
          :variant="submitBtnVariant || 'outline-danger'"
          :block="$store.getters[`app/${breakpoint}AndDown`]"
          :size="size"
          class="w-100"
          @click.prevent="$emit('delete')"
        >
          <feather-icon :icon="deleteBtnIcon || 'TrashIcon'" size="20" class="align-middle" />
          <span class="ml-25 align-middle"> {{ deleteBtnText || $t('action.delete') }} </span>
        </b-button>
      </b-col>
      <b-col v-if="showSubmit" v-bind="initCols">
        <b-button
          :id="`${formName}-form-action-btn-submit`"
          :disabled="submitDisabled"
          type="submit"
          :variant="submitBtnVariant || 'primary'"
          :block="$store.getters[`app/${breakpoint}AndDown`]"
          :size="size"
          class="w-100"
        >
          <feather-icon :icon="submitBtnIcon || 'SaveIcon'" size="20" class="align-middle" />
          <span class="ml-25 align-middle"> {{ submitBtnText || $t('action.save') }} </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AppFormActions',

  props: {
    formName: {
      type: String,
      default: '',
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    resetDisabled: {
      type: Boolean,
      default: false,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showSubmitAndNext: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: String,
      default: 'lg',
    },
    size: {
      type: String,
      default: 'md',
    },
    deleteBtnText: {
      type: String,
      default: '',
    },
    deleteBtnIcon: {
      type: String,
      default: '',
    },
    submitBtnText: {
      type: String,
      default: '',
    },
    submitBtnIcon: {
      type: String,
      default: '',
    },
    submitBtnVariant: {
      type: String,
      default: '',
    },
  },

  computed: {
    breakpoint() {
      return ['sm', 'xs', 'md', 'lg', 'xl'].indexOf(this.condensed) !== -1 ? this.condensed : 'lg'
    },
    colWidth() {
      let countActions = 0
      if (this.showSubmit) countActions += 1
      if (this.showReset) countActions += 1
      if (this.showCancel) countActions += 1
      if (this.showDelete) countActions += 1

      return 12 / countActions
    },
    initCols() {
      return {
        class: `d-flex mb-1 mb-${this.breakpoint}-0`,
        cols: 12,
        xs: this.breakpoint === 'xs' && this.colWidth,
        sm: this.breakpoint === 'sm' && this.colWidth,
        md: this.breakpoint === 'md' && this.colWidth,
        lg: this.breakpoint === 'lg' && this.colWidth,
        xl: this.breakpoint === 'xl' && this.colWidth,
      }
    },
  },
}
</script>
